var fs = require('fs')
var path = require('path')
var css = require('sheetify')
var choo = require('choo')
var fm = require('front-matter')

css('tachyons')
css('./assets/styles.css')

var app = choo()
if (process.env.NODE_ENV !== 'production') {
  app.use(require('choo-devtools')())
} else {
  app.use(require('choo-service-worker/clear')())
}

var articles = {
  'hello-world.md': fs.readFileSync(path.join('content', 'hello-world.md'), 'utf8')
}

app.use(function (state) {
  state.articles = articles
})

app.route('/', require('./views/main'))

Object.keys(articles).forEach(function (page) {
  var route = page.substring(0, page.length - 3)
  var content = fm(articles[page]).body // marked(articles[page])
  app.route(`/${route}`, require('./views/page')(content))
})

app.route('/*', require('./views/404'))

module.exports = app.mount('body')
