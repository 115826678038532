var html = require('choo/html')
var fm = require('front-matter')
var strftime = require('strftime')

module.exports = view

function view (state, emit) {
  // emit(state.events.DOMTITLECHANGE, 'jake burden')

  return html`
    <body class="avenir bg_dark white h-100">
      <div class='accent w-100'></div>
      <main class="cf pa3 pa4-m pa5-l mw9 center">
        <header class="fr w-100 w-80-l">
          <p class="f6">
            greetings 👋 👋
          </p>
          <h1 class="f2 f1-l lh-title mt0 mb4 mb5-ns fw1">
            i'm ${titleChanger()}
          </h1>
        </header>
        <div class="lh-copy fr w-100 w-80-l mb4">
          ${renderLinks(state)}
        </div>
        <footer class="fr w-100 w-80-l">
          <p class="f6">
            jake@burden.blog
          </p>
        </footer>
      </main>
    </body>
  `

  function titleChanger () {
    return html`
      <a onmouseenter=${mouse(state, emit, true)} onmouseout=${mouse(state, emit)} class='link white hover-capital' href='https://twitter.com/jekrb' title='twitter'>
        <span class='fw4'>jake </span>
        <span class='fw1'>burden</span>
      </a>
    `
  }
}

function mouse (state, emit, enter) {
  return function (e) {
    emit(state.events.DOMTITLECHANGE, enter ? 'Jake Burden' : 'jake burden')
  }
}

function renderLinks (state) {
  return Object.keys(state.articles).map(function (page) {
    var article = state.articles[page]
    var content = fm(article)
    console.log(content)
    var title = content.attributes.title
    var date = strftime('%B %o, %Y', content.attributes.date)
    var href = page.substring(0, page.length - 3)

    return html`
      <div class="fl-ns w-100 w-20-l pr3-m pr5-l mb4">
        <a class='white f5 no-underline' href='/${href}' title='${title}'>
          <p class='ma0'>${title}</p>
          <small class="fw6 f6 pink">${date}</small>
        </a>
      </div>
    `
  })
}