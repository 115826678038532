var html = require('choo/html')
var raw = require('choo/html/raw')
var marked = require('marked')

module.exports = function (page) {
  return function (state, emit) {
    return view(state, emit, page)
  }
}

function view (state, emit, page) {

  return html`
    <body class="avenir bg_dark white h-100">
      <div class='accent w-100'></div>
      <nav class="ph3 ph5-ns fr w-100 w-80-l">
         <a class="link white f6 f5-l fr mv4 mr3 mr4-l" href="/" title="Home">← home</a>
      </nav>
      <main class="pa4 ph7-l mw9-l center">
        ${render(page)}
      </main>
    </body>
  `
}

function render (src) {
  var renderer = new marked.Renderer()
  renderer.paragraph = function (text) {
    return `<p class="f5 f4-ns lh-copy measure">${text}</p>`
  }
  renderer.heading = function (text, level) {
    if (level === 1) return `<h1 class="f3 f1-m f-headline-l measure-narrow lh-title">${text}</h1>`
    if (level === 2) return `<h2 class="f4 f2-m f-subheadline-l measure-narrow lh-title">${text}</h2>`
    return `<h${level} class="f5 f3-m">${text}</h1>`
  }
  return raw(marked(src, { renderer }))
}
